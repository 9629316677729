<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getLists" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              required
              label="년도"
              type="year"
              v-model="searchParam.year"
              @datachange="getLists"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-equip
              label="설비"
              name="equipmentCd"
              v-model="searchParam.equipmentCd">
            </c-equip>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="editable"
              type="none"
              :comboItems="breakItems"
              itemText="codeName"
              itemValue="code"
              name="breakTypeCd"
              label="카탈로그"
              v-model="searchParam.breakTypeCd"
              @datachange="getLists"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-12">
          <apexchart 
            ref="chart" 
            height="350px" 
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </div>  
      <div class="col-12">
        <c-table
          ref="table"
          title="설비별 고장 건수"
          tableId="table"
          :merge="grid.merge"
          gridHeight="350px"
          :filtering="false"
          :columns="grid.columns"
          :data="grid.data"
        >
          
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'break-type-breat-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        year: '',
        breakTypeCd: 'WO_BD_PHENOMENON_CD'
      },
      chart: {
        chartOptions: {
          title: {
            // MTTF, MTBF
            text: '월별 카탈로그 고장건수'
          },
          chart: {
            height: 700,
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [3, 3, 6],
            curve: 'straight',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          yaxis: {
            title: {
              text: '시간'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 시간"
              }
            }
          }
        },
        series: [
        ],
        chartWidth: '80%',
      },
      grid: {
        columns: [
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      graphUrl: '',
      breakItems: [
        {
          code: 'WO_BD_PHENOMENON_CD',
          codeName: '현상',
        },
        {
          code: 'WO_BD_CAUSE_CD',
          codeName: '원인',
        },
        {
          code: 'WO_BD_ACTION_CD',
          codeName: '조치',
        },
      ],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // 년도 설정
      this.searchParam.year = this.$comm.getThisYear();
      this.listUrl = selectConfig.sts.bka.equip.url;
      this.graphUrl = selectConfig.sts.bka.breaktype.url;

      this.getLists();
      this.setHeader();
    },
    getLists() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '년도를 선택하세요.', // 년도를 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.getList();
      this.getGraph();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

      },);
    },
    getGraph() {
      this.$http.url = this.graphUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.chart.series = [];
        this.$_.forEach(_result.data, _item => {
          this.chart.series.push({
            name: _item.breakTypeName,
            data: [_item.mon1,_item.mon2,_item.mon3,_item.mon4,_item.mon5,_item.mon6,
                  _item.mon7,_item.mon8,_item.mon9,_item.mon10,_item.mon11,_item.mon12,]
          })
        })
        
        this.$refs['chart'].refresh();
        this.chart.chartWidth = '90%';
      })
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'plant_name',
          field: 'plant_name',
          label: '사업장',
          align: 'center',
          sortable: true,
          fix: true,
          style: 'width: 120px',
        },
        {
          name: 'equipment_num',
          field: 'equipment_num',
          label: '설비관리번호',
          align: 'center',
          sortable: true,
          fix: true,
          style: 'width: 150px',
        },
        {
          name: 'equipment_name',
          field: 'equipment_name',
          label: '설비명',
          align: 'left',
          sortable: true,
          fix: true,
          style: 'width: 200px',
        },
        {
          name: 'sum',
          field: 'sum',
          label: '총 고장건수',
          align: 'right',
          type: 'cost',
          currentDay: true,
          sortable: true,
          style: 'width: 80px',
        }
      ]

      this.$comm.getComboItems('WO_BD_ACTION_CD').then(action => {
        let actions = [];
        this.$_.forEach(action,  _item =>{
          actions.push({
            name: _item.code,
            field: _item.code,
            label: _item.codeName,
            align: 'right',
            sortable: true,
            style: 'width: ' + _item.attrVal1 +'px',
          })
        })
        this.grid.columns = this.$_.concat(this.grid.columns, {
          name: 'action',
          field: 'action',
          label: '조치 고장 건 수',
          child: actions
        })
        this.$comm.getComboItems('WO_BD_CAUSE_CD').then(cause => {
          let causes = [];
          let sum = 1;
          this.$_.forEach(cause,  _item =>{
            causes.push({
              name: _item.code,
              field: _item.code,
              label: _item.codeName,
              align: 'right',
              sortable: true,
              style: 'width: ' + _item.attrVal1 +'px',
            })
          })
          this.grid.columns = this.$_.concat(this.grid.columns, {
            name: 'cause',
            field: 'cause',
            label: '원인 고장 건 수',
            child: causes
          })
          this.$comm.getComboItems('WO_BD_PHENOMENON_CD').then(pheno => {
            let pehenos = [];
            this.$_.forEach(pheno,  _item =>{
              pehenos.push({
                name: _item.code,
                field: _item.code,
                label: _item.codeName,
                align: 'right',
                sortable: true,
                style: 'width: ' + _item.attrVal1 +'px',
              })
            })
            this.grid.columns = this.$_.concat(this.grid.columns, {
              name: 'pheno',
              field: 'pheno',
              label: '현상 고장 건 수',
              child: pehenos
            })
          })
        })
      })
      
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>